<template>
  <InputCard :scroll="scroll" />
</template>

<script>
import InputCard from '@/components/InputCard'

export default {
  name: 'Decrypt',
  data() {
    return {
      scroll: null
    }
  },
  components: {
    InputCard
  },
  beforeMount(){
    let scroll = this.$route.query.scroll;

    if(scroll) {
      this.scroll = scroll;
    }
  },
  mounted() {
    this.$nextTick(function () {

    })
  }
}
</script>


<style lang="scss">

</style>
