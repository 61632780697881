
export const vectors = {
  name: 'vectors',

  data() {
    return {
      IDs: [],
      vectors: [],
      characterSize: 17
    }
  },

  computed: {
    url() {
      return `${window.location.origin}/view?string=${encodeURIComponent(btoa(this.IDs))}`
    },
    SVGs() {
      return this.createSVGs(this.vectors) || ''
    }
  },

  methods: {
    getIds: function() {
      let members = document.querySelectorAll('[code="member"]')

      return [...members].map(path => path.id)
    },
    createSVGs: function(vectors) {
      const svg = set => `
        <svg viewBox="0 0 700 700" width="${this.characterSize}" height="${this.characterSize}">
          <g><path code="member" id="${set.id}" d="${set.vector}" /></g>
        </svg>
      `
      return vectors.map(set => svg(set)).join(' ')
    },
  }
}
