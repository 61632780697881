import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 1000
});

export default {
  get: (path) => {
    return instance.get(path)
  },
  post: (path, body) => {
    return instance.post(path, body)
  }
}
