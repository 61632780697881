<template>
  <InputCard />
</template>

<script>
import InputCard from '@/components/InputCard'

export default {
  name: 'Encrypt',
  components: {
    InputCard
  },
  beforeMount(){
    let scroll = this.$route.query.scroll;

    if(scroll) {
      this.$router.replace({ path: 'decrypt', query: { scroll } })
    }
  }
}
</script>


<style lang="scss">

</style>
