import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import App from './App.vue'
import './registerServiceWorker'
import html2canvas from 'html2canvas'
import router from './router'
import store from './store'
import './styles.scss'

import 'spinkit/spinkit.css'


Vue.use(VueAnalytics, {
  id: 'UA-218480162-2'
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueClipboard)
Vue.use(vueFilterPrettyBytes)

Vue.prototype.$html2canvas = html2canvas
Vue.config.productionTip = false

const loader = Vue.observable({
  loading: false,
  start() { loader.loading = true },
  stop()  { loader.loading = false }
});

Vue.prototype.$loader = loader;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
