import http from '@/services/http';

export const api = {
  name: 'api',
  data() {
    return {
      decrypted: '',
      qrcode: '',
      func: {
        encrypt: this.encrypt,
        decrypt: this.decrypt
      }
    }
  },

  methods: {
    load: function(ids) {
      this.$loader.start()

      http.post('/reftable', { ids })
      .then(resp => {
        this.vectors = resp.data.vectors
      })
      .catch(error => console.error(error))
      .finally(() => { this.$loader.stop() })
    },

    encrypt: function(data, passkey) {
      this.$loader.start()

      http.post('/encrypt', { data, passkey })
      .then(resp => {
        this.vectors = resp.data.vectors
      })
      .catch(error => console.error(error))
      .finally(() => { this.$loader.stop() })
    },

    decrypt: function(ids, passkey) {
      this.$loader.start()

      // split string into array
      if(typeof(ids) === 'string') {
        ids = ids.split(',')
      }
      return http.post('/decrypt', { ids, passkey })
      .then(resp => {
        this.decrypted = resp.data.content
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => { this.$loader.stop() })
    },

    generateQR: function(qrstring) {
      this.$loader.start()

      http.post('/qr', { qrstring })
      .then(resp => {
        this.qrcode = resp.data.qr;

        this.$bvModal.show('qr_display')
      })
      .catch(error => console.error(error))
      .finally(() => { this.$loader.stop() })
    }
  }
}
