
export const files = {
  name: 'Files',
  data() {
    return {
      files: []
    }
  },
  methods: {
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // console.log('response', newFile.response)

        if (newFile.xhr) {
          // console.log('status', newFile.xhr.status)

          if([ 200 ].includes(newFile.xhr.status)) {
            this.vectors = newFile.response.vectors
          }
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      // set passkey on upload
      newFile.passkey = this.passkey

      if (newFile && !oldFile) {

        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|pdf|doc|docx|txt|xls|xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    }
  }
}
