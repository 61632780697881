<template>
  <div class="encrypt">
    <!-- Visual Background Effect -->
    <div id="ids">
      <div class="ids-header"></div>
        {{IDs}}
      <div class="ids-footer"></div>
    </div>

    <!-- Encrypt/Decrypt card -->
    <b-card class="shadow input-card"
      text-variant="white"
      :title="$route.name + ' text or file'">
      <b-input-group class="shadow-sm my-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <b-icon icon="key" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input class="passkey-input" placeholder="Passkey" v-model="passkey" @keydown.space.prevent />
      </b-input-group>

      <b-tabs content-class="mt-0" justified>
        <b-tab :title="buttonText[operation].inputText" active>

          <b-textarea class="text-input" v-model="text" rows="10" placeholder="Type or paste text"></b-textarea>

          <b-navbar type="light" variant="light" class="border-top">
            <b-navbar-nav class="ml-auto"></b-navbar-nav>
            <b-navbar-nav>
              <b-button-toolbar>
                <b-button-group class="mx-1">
                  <b-button
                    variant="dark"
                    :disabled="passkey.length < 1 || text.length < 1"
                    @click="func[operation](text, passkey)">
                    <b-icon icon="shield-lock" /> {{buttonText[operation].submit.text}}
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-navbar-nav>
            <b-navbar-nav class="mr-auto"></b-navbar-nav>
          </b-navbar>
        </b-tab>

        <b-tab :title="buttonText[operation].inputFile" class="upload-tab">
          <b-list-group class="attached-files">
            <b-list-group-item v-for="file in files" :key="file.name" class="d-flex justify-content-between align-items-center">
              <span><strong>{{file.name}}</strong> - {{file.size | prettyBytes}}</span>

              <b-badge v-show="!file.error" variant="danger">error <b-icon icon="x-circle"/></b-badge>
              <b-badge v-show="file.success" variant="success">success <b-icon icon="check-circle"/></b-badge>
            </b-list-group-item>
          </b-list-group>


          <div class="file-uploads-disabled">
            <h5 class="mb-0 text-muted">Upload is currently disabled</h5>
          </div>

          <!-- <file-upload
            class="upload-zone"
            :class="$refs.upload && $refs.upload.dropActive && 'active'"
            :style="{'height': `${222 - filesHeight}px`}"
            ref="upload"
            v-model="files"
            :drop="true"
            :drop-directory="false"
            :data="{ passkey }"
            post-action="/api/upload"
            @input-file="inputFile"
            @input-filter="inputFilter">

            <div v-show="$refs.upload && !$refs.upload.dropActive">
              <h1 class="mb-0"><b-icon icon="plus"/></h1>
              <p>Drag & drop file or click to browse</p>
            </div>

            <span v-show="$refs.upload && $refs.upload.dropActive">
              <p>Drop File</p>
            </span>
          </file-upload> -->


          <b-navbar type="light" variant="light" class="border-top">
            <b-navbar-nav class="ml-auto"></b-navbar-nav>
            <b-navbar-nav>
              <b-button-toolbar>
                <b-button-group class="mx-1">
                  <b-button variant="dark" :disabled="files.length < 1" v-show="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
                    <b-icon icon="shield-lock" /> {{buttonText[operation].submit.file}}
                  </b-button>
                </b-button-group>
                <b-button-group class="mx-1">
                  <b-button variant="dark" v-show="$refs.upload && $refs.upload.active" @click.prevent="$refs.upload.active = false">
                    Stop upload
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-navbar-nav>
            <b-navbar-nav class="mr-auto"></b-navbar-nav>
          </b-navbar>
        </b-tab>
      </b-tabs>

    </b-card>

    <div v-show="hasOutput">
      <b-navbar class="output-action-bar" toggleable="lg" type="dark" variant="success">
        <b-navbar-brand>Output</b-navbar-brand>

        <b-navbar-nav class="ml-auto"></b-navbar-nav>
        <b-navbar-nav>
          <b-button-toolbar>
            <b-button-group class="mx-1" size="sm">
              <b-button type="button" variant="outline-light"
                v-clipboard:copy="IDs"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError">
                <strong><b-icon icon="clipboard-plus" font-scale="0.95" class="mb-1 mr-1"/> {{buttonText[operation].copy}}</strong>
              </b-button>
            </b-button-group>
            <b-button-group class="mx-1" size="sm" v-if="operation === 'encrypt'">
              <b-button variant="outline-light" @click="screenshot()">
                <strong><b-icon icon="download" font-scale="0.95" class="mb-1 mr-1"/> {{buttonText[operation].download}}</strong>
              </b-button>
            </b-button-group>
            <!-- <b-button-group class="mx-1" size="sm" v-if="operation === 'encrypt'">
              <b-button variant="outline-light" @click="generateQR(IDs.join(','))">
                <strong><b-icon icon="code-square" font-scale="0.95" class="mb-1 mr-1"/> {{buttonText[operation].qrcode}}</strong>
              </b-button>
            </b-button-group> -->
            <!-- <b-button-group class="mx-1" size="sm">
              <b-button variant="outline-light" @click="screenshot()">
                <strong><b-icon icon="file-zip" font-scale="0.95" class="mb-1 mr-1"/> {{buttonText[operation].downloadAll}}</strong>
              </b-button>
            </b-button-group> -->
          </b-button-toolbar>

        </b-navbar-nav>
        <!-- <b-navbar-nav class="mr-auto"></b-navbar-nav> -->
      </b-navbar>

      <div ref="output" id="output" class="shadow-lg"></div>

      <!-- QR Code modal -->
      <b-modal centered v-show="qrcode" size="sm" id="qr_display" title="QRCode Display" hide-header hide-footer>
        <br>
        <b-img fluid center :src="qrcode" />
        <br>
      </b-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import VueUploadComponent from 'vue-upload-component'

import { api } from '@/mixins/api'
import { files } from '@/mixins/files'
import { vectors } from '@/mixins/vectors'

export default {
  name: 'Home',
  props: [
    'scroll'
  ],
  components: {
    // FileUpload: VueUploadComponent
  },
  mixins: [
    api,
    files,
    vectors
  ],
  data() {
    return {
      passkey: '',
      text: this.$props.scroll || '',
      file: '',
      decrypted: '',
      buttonText: {
        encrypt: {
          inputText:   'Text Input',
          inputFile:   'File Upload',
          submit: {
            text: 'Encrypt Text',
            file: 'Encrypt File'
          },
          download:    'Download Scroll',
          qrcode:      'Download QR Code',
          copy:        'Copy Code',
          downloadAll: 'Download All Files'
        },
        decrypt: {
          inputText:   'Paste Code',
          inputFile:   'Upload Scroll',
          submit: {
            text: 'Decrypt Text',
            file: 'Decrypt Scroll'
          },
          download:    'Download Encrypted Scroll',
          copy:        'Copy Output Text',
          downloadAll: 'Download All Files'
        }
      }
    }
  },
  computed: {
    filesHeight() {
      return this.files.length * 50
    },
    operation() {
      return this.$route.name.toLowerCase()
    },
    hasOutput() {
      return this.vectors.length > 0 || this.decrypted.length > 0
    }
  },
  watch: {
    SVGs(newValue) {
      this.$refs.output.innerHTML = newValue;

      this.IDs = this.getIds()
    },
    decrypted(newValue) {
      this.$refs.output.innerText = newValue;
    },
    text(newValue) {
      // remove all linebreaks, tabs, and extra spaces from input text
      this.text = newValue.replace(/'/g, '').replace(/(?:\r|\n|\r\n|\s\s+)/g, ' ').replace(/\p{Extended_Pictographic}/ug, '')

      // let singleEmojis  = this.text.match(/\p{Emoji}/ug)
      // let groupedEmojis = this.text.match(/\p{Extended_Pictographic}/ug)
    },
    passkey(newValue) {
      this.passkey = newValue.replace(/\s/g, '')
    },
    qrcode(image) {
      console.log('image', image)
    }
  },
  methods: {
    onCopy: function() {
      alert('Code is copied to clipboard, paste it somewhere to save it!')
    },
    onCopyError: function() {
      alert('copy error')
    },
    screenshot: function() {
      this.$html2canvas(this.$refs.output, {
        scrollX: 0,
        scrollY: -window.scrollY
      })
      .then(canvas => {
        // Create link
        let link = document.createElement('a')

        // Convert canvas to image
        link.download = `scroll-${Date.now()}.png`
        link.href     = canvas.toDataURL('image/png');

        // Download
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
     });
    }
  }
}
</script>


<style lang="scss">
  .encrypt {
    #ids {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0.15;
      user-select: none;
      z-index: -1;

      & .ids-header, & .ids-footer {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 120px;
      }
      & .ids-header {
        top: 0;
        background: linear-gradient(#f2f2f2, rgba(0, 0, 0, 0));
      }
      & .ids-footer {
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0), #f2f2f2);
      }
    }

    .input-card {
      margin: 15px auto;
      max-width: 735px;
      height: 500px;
      background: var(--green);

      & .passkey-input {
        // background: #ccc;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      & .text-input {
        resize: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      & .upload-tab {
        position: relative;
      }

      & .attached-files {
        color: black;
        // position: absolute;
        // left: 15px;
        // width: calc(100% - 30px);
        z-index: 10;
      }

      & .file-uploads, .file-uploads-disabled {
        // height: 221px;
        width: calc(100% - 30px);
        background: #eee;
        color: #444;
        margin: 15px;
        border: 3px dashed #ccc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          background: #f5f5f5;
          border-color: #ddd;
        }
      }

      & .file-uploads-disabled {
        height: 220px;
        user-select: none;
      }
    }

    & .output-action-bar {
      max-width: 735px;
      margin: 30px auto 0;
      border-radius: 4px 4px 0 0;
    }

    #output {
      background: #fbf9f3;
      padding: 10px;
      max-width: 735px;
      margin: 0 auto 100px auto;
      overflow: auto;
      text-align: left;
    }

    .qrcode-modal {
      text-align: center;
    }
  }
</style>
