<template>
  <div id="app">
    <Loader v-if="$loader.loading" />

    <b-navbar type="light" variant="transparent">
      <div class="container">
        <b-navbar-brand href="#">CEFER</b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item router-link to="/">Encrypt</b-nav-item>
            <b-nav-item router-link to="/decrypt">Decrypt</b-nav-item>
            <b-nav-item router-link to="/about">About</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'App',
  components: { Loader }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
